<!--退砼-->
<template>
    <div class="back-concrete">
        <table id="concreteTable">
            <tr>
                <th>剩退单号</th>
                <td>{{ form.pcb_number }}</td>
                <th>当前车次</th>
                <td>{{ form.ljcs }}</td>
            </tr>
            <tr>
                <th>强度等级</th>
                <td>{{ form.strength_grade_name }}</td>
                <th>特性要求</th>
                <td>{{ form.special_require_name }}</td>
            </tr>
            <tr>
                <th>工程名称</th>
                <td>
                    {{ form.project_name }}
                </td>
                <th>客户名称</th>
                <td>{{ form.customer_name }}</td>
            </tr>
            <tr>
                <th>发货方量</th>
                <td>{{ form.volume }}</td>
                <th>签收方量</th>
                <td><input v-model="form.sign_volume" :disabled="disable" placeholder="请输入签收方量"></td>
            </tr>
            <tr>
                <th>剩退总方量</th>
                <td>
                    <input
                        v-model="form.surplus_volume"
                        :disabled="disable"
                        placeholder="请输入剩退总方量"
                    >
                </td>
                <th>退砼方量</th>
                <td><input v-model="form.back_volume_temp" :disabled="disable" placeholder="请输入退砼方量"></td>
            </tr>
            <tr>
                <th>卸料方量</th>
                <td colspan="3">
                    <input v-model="form.unload_volume" :disabled="disable" placeholder="请输入卸料方量">
                </td>
            </tr>
            <tr>
                <th>盘盈方量</th>
                <td><input v-model="form.overage_volume" :disabled="disable" placeholder="请输入盘盈方量"></td>
                <th>盘亏方量</th>
                <td><input v-model="form.inventory_loss" :disabled="true" placeholder=""></td>
            </tr>
            <tr>
                <th>处置方式</th>
                <td>
                    <el-radio
                        v-model="form.handle_style"
                        :disabled="disable"
                        :label="1"
                        @change="handleLose"
                    >
                        砂石分离
                    </el-radio>
                    <el-radio
                        v-model="form.handle_style"
                        :disabled="disable"
                        :label="3"
                        @change="HandleTrans"
                    >
                        转发
                    </el-radio>
                </td>
                <th>处置原因</th>
                <td><input v-model="form.handle_reason" :disabled="disable" placeholder="请输入处置原因"></td>
            </tr>
            <tr>
                <th>操作人</th>
                <td>{{ form.oper_name }}</td>
                <th>质检员</th>
                <td>
                    <input
                        :disabled="disable"
                        v-model="form.censor_name"
                        @focus="selectCensor"
                        readonly
                        placeholder="请输入质检员"
                    >
                </td>
            </tr>
            <tr>
                <th>定期皮重</th>
                <td>{{ form.last_tare_weight }}</td>
                <th>当前皮重</th>
                <td>{{ form.tare_weight }}</td>
            </tr>
        </table>
        <div class="btns">
            <el-button
                class="btn-save"
                type="primary"
                :loading="btnSaveLoading"
                @click="saveData()"
                v-if="!disable"
            >
                保存
            </el-button>
            <el-button
                class="btn-save-push"
                type="primary"
                v-print="'#concreteTable'"
            >
                打印
            </el-button>
            <el-button class="btn-close" @click="handleClose">
                关闭
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'back-concrete',
    data() {
        return {
            form: {
                pcb_number: '',
                ljcs: 0,
                strength_grade_name: '',
                special_require_name: '',
                project_name: '',
                customer_name: '',
                volume: 0,
                sign_volume: 0,
                surplus_volume: 0,
                back_volume_temp: 0,
                unload_volume: 0,
                overage_volume: 0,
                handle_style: 0,
                handle_reason: '',
                oper: '',
                oper_name: '',
                censor: '',
                censor_name: '',
            },
            btnSaveLoading: false,
            mixstation_code: '',
            disable: false,
        };
    },
    props: {
        extr: Object,
    },
    created() {
        this.init();
    },
    watch: {
        // 'form.surplus_volume': {
        //     handler() {
        //         this.change_surplus_volume();
        //     },
        // },
        // 'form.sign_volume': {
        //     handler() {
        //         this.change_surplus_volume();
        //     },
        // },
        // 'form.overage_volume': {
        //     handler(val) {
        //         if (val < 0) {
        //             this.form.inventory_loss = -val;
        //         } else {
        //             this.form.inventory_loss = 0;
        //         }
        //     },
        // },
    },
    methods: {

        // 初始化数据
        init() {
            this.mixstation_code = this.extr.mixstation_code;
            this.form.pcb_number = this.extr.pcb_number;
            // eslint-disable-next-line eqeqeq
            this.disable = Boolean(this.extr.is_forward);
            this.$axios.get('/interfaceApi/production/productpcb/get_pcb_surplus/' + this.form.pcb_number)
                .then(data => {
                    this.form = data;
                    if (!this.form.oper_name) {
                        this.form.oper = this.$takeTokenParameters('Uid');
                        this.form.oper_name = this.$takeTokenParameters('Name');
                    }
                    this.LoadBackVolume();
                    this.initWatch();
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        initWatch() {
            this.$watch('form.surplus_volume', this.change_surplus_volume);
            this.$watch('form.sign_volume', this.change_surplus_volume);
            this.$watch('form.overage_volume', val => {
                if (val < 0) {
                    this.form.inventory_loss = -val;
                } else {
                    this.form.inventory_loss = 0;
                }
            });
        },
        // 保存数据
        saveData() {
            if (!this.Verify()) {
                return;
            }
            this.btnSaveLoading = true;
            this.$axios.post('/interfaceApi/production/productpcb/pcb_surplus', this.form)
                .then(() => {
                    window.updateTable();
                    this.$message.success('保存成功');
                    this.handleClose();
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                }).finally(() => {
                    this.btnSaveLoading = false;
                });
        },
        // 选择质检员
        selectCensor() {
            this.$toast({
                title: true,
                text: '选择质检员',
                type: 'eject',
                width: '70%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'censor_user_info',
                        QueryString: `mixstation_code=${this.mixstation_code}`,
                    },
                    selectTypeCallback: this.confirmSelect,
                },
            });
        },
        // 确认选择质检员
        confirmSelect(data) {
            this.form.censor = data.User_Id;
            this.form.censor_name = data.User_Name;
        },
        // 倒掉
        handleLose() {
            this.change_handle_style();
        },
        // 转发
        HandleTrans() {
            this.change_handle_style();
        },
        // 关闭
        handleClose() {
            this.$parent.hide();
        },
        isNumber(val) {
            const regPos = /^\d+(\.\d+)?$/; // 非负浮点数
            const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; // 负浮点数
            if (regPos.test(val) || regNeg.test(val)) {
                return true;
            }
            return false;
        },
        LoadBackVolume() {
            parent.$globalHub.$axios.get('/interfaceApi/production/productpcbbacklog/backvolume/' + this.form.pcb_number)
                .then(result => {
                    this.form.back_volume_temp = result;
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        change_surplus_volume() {
            const flag = this.Verify();
            if (flag) {
                const surplus_volume = this.form.surplus_volume;
                const volume = this.form.volume;
                const sign_volume = this.form.sign_volume;
                let back_volume = 0.00;
                let overage_volume = 0.00;
                if (!this.isNumber(surplus_volume)) {
                    this.$message.warning('【剩退总方量录入错误】');
                    return false;
                }
                const handleStyle = this.form.handle_style;
                if (handleStyle === 1) {
                    overage_volume = -(volume - sign_volume);
                    back_volume = volume - sign_volume;

                    // 剩退总方量=发货方量-签收方量
                    const stz = volume - sign_volume;
                    this.form.surplus_volume = stz > 0 ? stz : 0;
                }
                if (handleStyle === 3) {
                    overage_volume = surplus_volume - (volume - sign_volume);
                    back_volume = volume - sign_volume;
                }
                this.form.overage_volume = overage_volume.toFixed(2);
                this.form.back_volume_temp = back_volume.toFixed(2);
            }
        },
        change_handle_style() {
            this.change_surplus_volume();
        },
        Verify() {
            if (this.form.handle_style === 0 || this.form.handle_style === null) {
                this.$message.warning('请选择处置方式');
                return false;
            }
            return true;
        },
    },
};
</script>

<style lang="stylus" scoped>
.back-concrete{
    padding 0.1rem 0.3rem;
    height 100%;
    overflow auto;
    position relative;

    .btns{
        width 100%;
        display flex
        justify-content center;
        align-items center;
        margin 0.3rem;
        position absolute
        bottom 0.2rem;
        .el-button,/deep/ .el-button{
            width: 2.6rem;
            height: 0.5rem;
            margin 0 0.25rem;
        }
        .btn-save,.btn-save-push{
            background: #1577D2;
        }
        .btn-close{
            background #fff!important
            border: 1px solid #1577D2;
            color #1577D2;
        }
    }
}
#concreteTable{
    width: 100%;
    table-layout fixed
    border-collapse collapse
    border:none;
    margin 0.1rem 0;
    tr{
        th,td{
            padding 0.1rem 0.1rem 0.1rem 0.15rem;
            font-size 0.18rem;
            color #333333;
            text-align left;
            border: 1px solid #D7D7D7;
            .name{
                width 1.8rem;
            }
        }
        th{
            width 1.7rem;
            background #F6F8FC;
            color #022782;
            font-weight 400;
            white-space nowrap
            position relative
            &.required:before{
                position: absolute;
                left: 0.04rem;
                content: "*";
                color: #f56c6c;
                margin-right: 4px;
            }
        }
        td{
            input,/deep/ input,.el-input,/deep/ .el-input{
                border 0;
                width 100% !important;
                height 100%;
                font-size 0.18rem;
                color #333333
                padding-left 0;
                background transparent;
            }
            .el-date-picker{
                width 100%;
                height 100%;
                border 0;
            }
            .el-input__prefix,/deep/ .el-input__prefix{
                right 5px;
                left auto;
            }
        }
        .title{
            width 100%;
            text-align center;
            background #EDF0F5;
        }
    }
}
</style>
